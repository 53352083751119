import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-filters" }
const _hoisted_2 = { class: "toggle-group" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
        return (_openBlock(), _createElementBlock("button", {
          key: filter,
          class: _normalizeClass(["button", { 'button--primary': _ctx.activeFilter === filter }]),
          onClick: ($event: any) => (_ctx.setFilter(filter))
        }, _toDisplayString(filter), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}