
import { PropType, defineComponent } from "vue"
import AppTodoItem from "./AppTodoItem.vue"
import { Todo } from "@/types/Todo";


export default defineComponent({
	components: {
		AppTodoItem,
	},
	props: {
		todos: {
			type: Array as PropType<Todo[]>
		}
	},
	methods: {
		toggleTodo(id: number) {
			this.$emit('toggleTodo', id)
		},
		removeTodo(id: number) {
			this.$emit('removeTodo', id)
		}
	},
	emits: {
		toggleTodo: (id: number) => Number.isInteger(id),
		removeTodo: (id: number) => Number.isInteger(id),
	}
})
