import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "todo-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTodoItem = _resolveComponent("AppTodoItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.todos, (todo) => {
      return (_openBlock(), _createBlock(_component_AppTodoItem, {
        key: todo.id,
        todo: todo,
        onToggleTodo: _ctx.toggleTodo,
        onRemoveTodo: _ctx.removeTodo
      }, null, 8, ["todo", "onToggleTodo", "onRemoveTodo"]))
    }), 128))
  ]))
}