
	import { Todo } from "@/types/Todo"
	import { defineComponent, PropType } from "vue"


	export default defineComponent({
		props: {
			todo: {
				type: Object as PropType<Todo>,
				required: true,
			}
		},
		methods: {
			// toggleTodo() {
			// 	this.$emit('toggle-todo', this.todo?.id)

			// },
			// removeTodo() {
			// 	this.$emit('remove-todo', this.todo?.id)
			// },
		},
		emits: {
			toggleTodo: (id : number) => Number.isInteger(id),
			removeTodo: (id : number) => Number.isInteger(id),
		}
	})
