
import { PropType, defineComponent } from 'vue';

interface Stats {
	done: number,
	active: number,
}

export default defineComponent({
	props: {
		stats: {
			type: Object as PropType<Stats>,
				required: true
		}
	},
})
