
	import { defineComponent } from "vue"
	import { Todo } from "@/types/Todo"

	interface State {
		isFormVisible : boolean,
		todoText: string,
	}

	export default defineComponent({
		data() : State {
			return {
				isFormVisible: false,
				todoText: '',
			}
		},
		methods: {
			showForm() {
				this.isFormVisible = true
			},
			closeForm() {
				this.isFormVisible = false
			},
			addTodo() {
				this.$emit('addTodo', {
					id: Date.now(),
					text: this.todoText,
					completed: false,
				})
				this.todoText = ''
			}
		},
		emits: {
			addTodo: (todo: Todo) => todo
		}
	})
