import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppFilters = _resolveComponent("AppFilters")!
  const _component_AppTodoList = _resolveComponent("AppTodoList")!
  const _component_AppAddTodo = _resolveComponent("AppAddTodo")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader),
    _createVNode(_component_AppFilters, {
      "active-filter": _ctx.activeFilter,
      onSetFilter: _ctx.setFilter
    }, null, 8, ["active-filter", "onSetFilter"]),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_AppTodoList, {
        todos: _ctx.filteredTodos,
        onToggleTodo: _ctx.toggleTodo,
        onRemoveTodo: _ctx.removeTodo
      }, null, 8, ["todos", "onToggleTodo", "onRemoveTodo"]),
      _createVNode(_component_AppAddTodo, { onAddTodo: _ctx.addTodo }, null, 8, ["onAddTodo"])
    ]),
    _createVNode(_component_AppFooter, { stats: _ctx.stats }, null, 8, ["stats"])
  ], 64))
}