
import { Filter } from '@/types/Filter';
import { PropType, defineComponent } from 'vue';

interface State {
	filters: Filter[],
}

export default defineComponent({
	props: {
		activeFilter: {
			type: String as PropType<Filter>,
			required: true,
		}
	},
	data(): State {
		return {
			filters: ['All', 'Active', 'Done']
		}
	},
	methods: {
		setFilter(filter: Filter) {
			this.$emit('setFilter', filter)
		}
	},
	emits: {
		setFilter: (filter: Filter) => filter
	}
})
